import axios from "axios";
import {SET_ASSETS, ADD_ASSET, GET_ASSETS} from "../actions/actionTypes"

export const getAssets = (bookId)=>{
    return((dispatch)=>{
        //console.log("b")
        const promise = new Promise(async(resolve,reject)=>{
            try {
                const assets = await axios.post(global.backend+'books/get_assets/'+bookId)
                /*
                if(assets.data.length==0){
                    reject("empty")
                }
                */
                //console.log(assets.data.length==0)
                dispatch(setAssets(assets.data))
                resolve(assets.data)
            } catch(e) {
                console.log(e)
            }
        })
        return promise
        .then(val=>{
            return val
        })
        .catch(
            (error)=>{
                return error
            }
        )

    })
}

export const createAsset = (data) => {
    //console.log(data)
    return((dispatch)=>{
        const promise = new Promise(async(resolve,reject)=>{
            try{
            var response
                response = await axios.post(global.backend+'assets', {
                    book_id: data.bookId,
                    name: data.name,
                    desc: data.desc,
                    category: data.category,
                    type:data.type,
                    amount:data.amount,
                    active:1,
                })
            
            if(response.status=="200"||response.status=="201"){
                //props.handleClose()
                resolve(true)
            }
            //console.log(response.status)
            }catch(e){
                console.log(e)
            }
    //navigate("/books")
        })
        return promise
        .then(val=>{
            dispatch(getAssets(data.bookId))
            return val
        })
    })
}

export const updateAsset = (data) => {
    console.log(data)
    return((dispatch)=>{
        const promise = new Promise(async(resolve,reject)=>{
            try{
            var response
                response = await axios.post(global.backend+'assets/update/'+data.assetId, {
                    book_id: data.bookId,
                    name: data.name,
                    desc: data.desc,
                    category: data.category,
                    type:data.type,
                    amount:data.amount,
                    active:data.active,
                })
            
            if(response.status=="200"||response.status=="201"){
                //props.handleClose()
                resolve(true)
            }
            console.log(response)
            }catch(e){
                console.log(e)
            }
    //navigate("/books")
        })
        return promise
        .then(val=>{
            dispatch(getAssets(data.bookId))
            return val
        })
    })
}

export const setAssets = (item) => {
    //AsyncStorage.setItem("grow:auth:user",user.uid);
    //console.log(user)
    //addUser(user)
    //console.log(item)
    return{
        type: SET_ASSETS,
        item: item,
    }
}
export const addAsset = (item) => {
    //AsyncStorage.setItem("grow:auth:user",user.uid);
    //console.log(user)
    //addUser(user)
    //console.log(item)
    return{
        type: ADD_ASSET,
        item: item,
    }
}
