//import AsyncStorage from '@react-native-community/async-storage';
//import auth from '@react-native-firebase/auth';
//import database from '@react-native-firebase/database';
import axios from "axios";
import store from '../configureStore';
import {SET_BOOK} from "../actions/actionTypes"

//const UUID = require("uuid-v4");



export const getBook = (id) => {
    return((dispatch)=>{
        //console.log("b")
        const promise = new Promise(async(resolve,reject)=>{
            try {
                const book = await axios.get(global.backend+'books/'+id)
                //console.log(book.data)
                if(Object.keys(book.data).length!==0){
                    dispatch(setBook(book.data[0]));
                //console.log(books)
                resolve(book);
                }
            } catch(e) {
                console.log(e)
            }
        })
        return promise
        .then(val=>{
            if(val){
                //dispatch(setUser(val))
                //console.log(val)
                return val
                //return (val)

            }
            
        })

    })
}

export const getBooksAccess = (id) => {
    return((dispatch)=>{
        //console.log("b")
        const promise = new Promise(async(resolve,reject)=>{
            try {
                const book = await axios.get(global.backend+'access/'+id)
                //console.log(book.data)
                if(Object.keys(book.data).length!==0){
                    dispatch(setBook(book.data));
                //console.log(book)
                resolve(book);
                }
            } catch(e) {
                console.log(e)
            }
        })
        return promise
        .then(val=>{
            if(val){
                //dispatch(setBook(val))
                //console.log(val)
                return val
                //return (val)

            }
            
        })

    })
}

export const createBook = async (data) => {
    return((dispatch)=>{
        //console.log("b")
        const promise = new Promise(async(resolve,reject)=>{
            try {
                const response = await axios.post(global.backend+'books', {
                    name: data.name,
                    desc: data.desc,
                    role:1,
                    status:1,
                    user_id:data.user_id, 
                    
                })
                console.log(response)
            } catch(e) {
                console.log(e)
            }
        })
        return promise
        .then(val=>{
            if(val){
                dispatch(getBook(data.id))
                //console.log(val)
                return val
                //return (val)

            }
            
        })

    })
}

export const updateBook = (data) => {
    return((dispatch)=>{
        //console.log(data)
        const promise = new Promise(async(resolve,reject)=>{
            try {
                const response = await axios.post(global.backend+'books/update/'+data.bookId, {
                    name: data.name,
                    desc: data.desc,
                })
                if(response.status=="200"||response.status=="201"){
                    //props.handleClose()
                    resolve(true)
                }else{
                    resolve(false)
                }
                }catch(e){
                    console.log(e)
                }
        })
        return promise
        .then(val=>{
            if(val){
                dispatch(getBooksAccess(data.user_id))
                //console.log(val)
                return val
            }
        })
    })
}

export const updateBookHistory = (data) => {
    return((dispatch)=>{
        
        var state = store.getState()
        //console.log(state)
        var data =[]
        //console.log(state)
        var month = new Date(state.books.active_month).getMonth()+1
        var year = new Date(state.books.active_month).getFullYear()

        for (let key in state.assets) {    
            //console.log(state.assets[key])  
            var temp={}
            
            /*
            for (let [key1, value] of Object.entries(state.assets[key])) {   
                console.log(key1, value) 
            }
                */
                //console.log(state.assets[key]["book_id"])
            temp["book_id"]=state.assets[key]["book_id"]
            temp["month"]=month
            temp["year"]=year
            temp["item_id"]=state.assets[key]["asset_id"]
            temp["item_amount"]=state.assets[key]["amount"]
            temp["item_category"]=state.assets[key]["category"]
            temp["check_by"]=state.user["id"]
            //console.log(temp)
            //if (state.assets[key]) data.push(key);
            data.push(temp)
            //console.log(data.length)
        }
        //console.log(data)
        //console.log(JSON.stringify(data))
        const promise = new Promise(async(resolve,reject)=>{
            try {
                //console.log(state)
                const response = await axios.post(global.backend+'bookHistory', {
                    data: JSON.stringify(data),
                    
                })
                if(response.status=="200"||response.status=="201"){
                    //props.handleClose()
                    resolve(true)
                }
                //console.log(response)
            } catch(e) {
                console.log(e)
            }
        })
        return promise
        .then(val=>{
            if(val){
                var data=[]
                data.id = state.books.book_id
                data.month = new Date()
                dispatch(updateBookMonth(data))
                //console.log(val)
                return val
                //return (val)

            }
            
        })

    })
}

export const updateBookMonth = (data) => {
    return((dispatch)=>{
        //console.log("b")
        const promise = new Promise(async(resolve,reject)=>{
            try {
                const response = await axios.post(global.backend+'books/update/'+data.id, {
                    active_month: data.month,
                })
                console.log(response)
                //console.log(response.data.messages['bookId'])
                //localStorage.setItem("bookId",this.props.id)
                //this.setState({navigate:"/"})
            
            } catch(e) {
                console.log(e)
            }
        })
        return promise
        .then(val=>{
            if(val){
                dispatch(getBook(data.id))
                //console.log(val)
                return val
                //return (val)

            }
            
        })

    })
}


export const updateUser = async (user) => {
    //e.preventDefault();
    let response = await axios.post(global.backend+'users/'+this.state.action+'/'+this.state.user_id, {
        user_id: this.data.user_id,
        email: this.data.user_email,
        name: this.state.name,
        phone: this.state.phone,
        dob: this.formatDate(this.state.dob),
        profession: this.state.profession,
        sex: this.state.sex,
        location: this.state.location,
        marriage: this.state.marriage,
        smoking: this.state.smoking,
        bio: this.state.bio
    
    })
    this.setState({submitted:true})
    //console.log(response)
    //navigate("/")
}

export const setBook = (item) => {
    //AsyncStorage.setItem("grow:auth:user",user.uid);
    //console.log(user)
    //addUser(user)
    //console.log(item)
    return{
        type: SET_BOOK,
        item: item,
    }
}