import {SET_BOOK} from "../actions/actionTypes"

const initialState ={
    
}
const reducer =(state = initialState, action) =>{
    switch(action.type){
        case SET_BOOK:
            return {
                name:action.item.name,
                desc:action.item.desc,
                active_month:action.item.active_month,
                book_id:action.item.id
            }
            
        
        default:
            return state;
    }
}
export default reducer;